import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const WhyChooseSolarEnergy = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/why-go-solar')
  }

  return (
    <div className="relative p-4 bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg max-w-full h-auto flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
      <div className="relative z-10 p-4 flex flex-col justify-center items-center">
        <h1 className="text-gray-300 font-bold text-lg sm:text-2xl md:text-3xl lg:text-4xl leading-tight">
          {t('whyChooseSolarEnergy')}
        </h1>
        <p className="text-yellow-500 font-bold text-base sm:text-lg md:text-xl lg:text-2xl mt-2 leading-snug max-w-xs sm:max-w-md">
          {t('whyChooseSolarEnergyBodyOne')} <br />
          {t('whyChooseSolarEnergyBodyTwo')}
        </p>
        <button
          className="mt-4 px-4 py-2 bg-yellow-500 text-gray-900 text-base sm:text-lg font-semibold rounded-full hover:bg-yellow-600 transition duration-300"
          onClick={handleButtonClick}
        >
          {t('learnMore')}
        </button>
      </div>
    </div>
  )
}

export default WhyChooseSolarEnergy
