import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaViber } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function useScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
}

const Footer = () => {
  const { t } = useTranslation()
  useScrollToTop()

  const location = useLocation()
  const currentPath = location.pathname

  let showLinks = {
    showWhyGoSolar: false,
    showAbout: false,
    showContacts: false,
  }

  if (currentPath === '/') {
    showLinks.showWhyGoSolar = true
    showLinks.showAbout = true
  } else if (currentPath === '/why-go-solar') {
    showLinks.showAbout = true
    showLinks.showContacts = true
  } else if (currentPath === '/about') {
    showLinks.showWhyGoSolar = true
    showLinks.showContacts = true
  }

  return (
    <div className="px-20">
      <div className="p-10 bg-gradient-to-br from-gray-800 to-black text-yellow-500 rounded-lg shadow-lg">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col items-center md:items-start text-center md:text-left mb-4 md:mb-0">
            <p className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 tracking-wide">
              {t('kktEngineering')}
            </p>
            <p className="italic mb-2">{t('poweringTheFuture')}</p>
            <p className="mb-2">{t('address')}</p>
            <p>
              {t('phone')}
              <a
                href="tel:+359899159416"
                className="hover:text-yellow-400 transition duration-300"
              >
                <span className="pl-1">+359 89 915 9416</span>
              </a>
            </p>
            <p className="flex items-center">
              <a
                href="viber://chat?number=%2B359899159416"
                className="hover:text-yellow-400 transition duration-300 flex items-center"
              >
                <FaViber className="mr-1" size={20} />
                <span className="pl-1">+359 89 915 9416</span>
              </a>
            </p>
            <p>
              {t('email')}
              <a
                href="mailto:kkt.eng@hotmail.com"
                className="hover:text-yellow-400 transition duration-300 pl-1"
              >
                kkt.eng@hotmail.com
              </a>
            </p>
          </div>

          <div className="flex space-x-8 mt-4 md:mt-0">
            {showLinks.showWhyGoSolar && (
              <Link
                to="/why-go-solar"
                className="hover:text-yellow-400 hover:underline transition duration-300 text-lg sm:text-xl"
              >
                {t('whyGoSolar')}
              </Link>
            )}
            {showLinks.showAbout && (
              <Link
                to="/about"
                className="hover:text-yellow-400 hover:underline transition duration-300 text-lg sm:text-xl"
              >
                {t('about')}
              </Link>
            )}
            {showLinks.showContacts && (
              <Link
                to="/"
                className="hover:text-yellow-400 hover:underline transition duration-300 text-lg sm:text-xl"
              >
                {t('home')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
