import React from 'react'
import WhyGoSolarBottomPart from './whyGoSolarBottomPart'
import { useTranslation } from 'react-i18next'

const WhyGoSolarPage = () => {
  const { t } = useTranslation()

  const cardContents = [
    {
      title: t('cards.environmentalImpact.title'),
      content: t('cards.environmentalImpact.content'),
    },
    {
      title: t('cards.financialSavings.title'),
      content: t('cards.financialSavings.content'),
    },
    {
      title: t('cards.propertyValueIncrease.title'),
      content: t('cards.propertyValueIncrease.content'),
    },
    {
      title: t('cards.planningForTheFuture.title'),
      content: t('cards.planningForTheFuture.content'),
    },
  ]

  return (
    <>
      <div className="pb-10 px-4 sm:px-10 md:px-20">
        <div className="p-6 sm:p-8 bg-panoramic-solar-panel text-yellow-500 rounded-lg flex flex-col justify-center items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl h-auto sm:h-96">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 leading-tight">
            {t('empowerYourHome')}
          </h1>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl max-w-xs sm:max-w-md md:max-w-lg">
            {t('empowerYourHomeBody')}
          </p>
        </div>
      </div>

      <div className="pb-10 px-4 sm:px-10 md:px-20">
        <div className="p-4 sm:p-6 bg-gradient-to-r from-gray-700 to-gray-800 text-yellow-500 rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl h-auto sm:h-32">
          <h3 className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold text-center">
            {t('advantagesOfSolarEnergy')}
          </h3>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-10 px-4 sm:px-10 md:px-20">
        {cardContents.map(({ title, content }, index) => (
          <div
            key={index}
            className="p-4 sm:p-6 bg-gray-800 text-yellow-500 rounded-lg flex flex-col justify-center items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mb-2">
              {title}
            </p>
            <p className="text-sm sm:text-base md:text-lg">{content}</p>
          </div>
        ))}
      </div>

      <div className="pb-10 px-4 sm:px-10 md:px-20">
        <div className="p-4 sm:p-6 bg-gradient-to-r from-gray-800 to-black text-yellow-500 rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl h-auto sm:h-64">
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center">
            {t('solarTechnologyExplained')}
          </p>
        </div>
      </div>

      <WhyGoSolarBottomPart />
    </>
  )
}

export default WhyGoSolarPage
