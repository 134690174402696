import React from 'react'
import { useTranslation } from 'react-i18next'

const HowCanWeHelpYou = () => {
  const { t } = useTranslation()

  return (
    <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 text-gray-300 rounded-lg text-center flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl">
      <h1 className="font-bold text-xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-6xl">
        {t('learMoreAboutUs')}
      </h1>
      <p className="text-yellow-500 font-bold text-base sm:text-lg md:text-xl lg:text-2xl mt-2 leading-snug max-w-xs sm:max-w-md">
        {t('learMoreAboutUsBody')}
      </p>
    </div>
  )
}

export default HowCanWeHelpYou
