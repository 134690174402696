import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaBars, FaTimes } from 'react-icons/fa'

const LanguageBanner = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => setMenuOpen(!menuOpen)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setMenuOpen(false)
  }

  return (
    <div className="bg-gradient-to-r from-gray-800 to-gray-900 h-auto w-full rounded-lg flex flex-col sm:flex-row items-center justify-between px-4 sm:px-6 py-2">
      <div className="sm:hidden flex items-center justify-center w-full">
        <button
          onClick={toggleMenu}
          className="text-yellow-500 focus:outline-none"
        >
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>
      <div
        className={`flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-2 sm:mt-0 ${menuOpen ? 'block' : 'hidden'} sm:flex`}
      >
        <Link
          to="/"
          onClick={() => setMenuOpen(false)}
          className="flex items-center justify-center sm:justify-start"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/kktlogo.png`}
            alt="KKT Logo"
            className="h-14 w-14 sm:h-14 sm:w-14 md:h-16 md:w-16 lg:h-18 md:w-18object-contain rounded-full hover:shadow-lg transition-transform transform hover:scale-105"
          />
        </Link>
        {location.pathname !== '/about' && (
          <Link
            to="/about"
            onClick={() => setMenuOpen(false)}
            className="flex items-center justify-center text-sm md:text-base lg:text-lg text-yellow-500 font-bold hover:text-yellow-400 transition duration-300 transform hover:translate-x-1 md:hover:translate-x-2 bg-gray-800 px-2 md:px-3 py-1 md:py-1.5 rounded-full shadow-lg"
          >
            {t('about')}
          </Link>
        )}
        {location.pathname !== '/why-go-solar' && (
          <Link
            to="/why-go-solar"
            onClick={() => setMenuOpen(false)}
            className="flex items-center justify-center text-sm md:text-base lg:text-lg text-yellow-500 font-bold hover:text-yellow-400 transition duration-300 transform hover:translate-x-1 md:hover:translate-x-2 bg-gray-800 px-2 md:px-3 py-1 md:py-1.5 rounded-full shadow-lg"
          >
            {t('whyGoSolar')}
          </Link>
        )}
      </div>
      <div
        className={`flex flex-col sm:flex-row items-center space-x-2 mt-2 sm:mt-0 ${menuOpen ? 'flex' : 'hidden'} sm:flex`}
      >
        {i18n.language !== 'en' && (
          <button
            onClick={() => changeLanguage('en')}
            className="text-xs sm:text-sm px-2 sm:px-3 py-1 sm:py-1.5 bg-yellow-500 text-gray-900 rounded-lg transition transform hover:scale-105 hover:shadow-xl hover:bg-yellow-600"
          >
            {t('english')}
          </button>
        )}

        {i18n.language !== 'bg' && (
          <button
            onClick={() => changeLanguage('bg')}
            className="text-xs sm:text-sm px-2 sm:px-3 py-1 sm:py-1.5 bg-yellow-500 text-gray-900 rounded-lg transition transform hover:scale-105 hover:shadow-xl hover:bg-yellow-600"
          >
            {t('bulgarian')}
          </button>
        )}
      </div>
    </div>
  )
}

export default LanguageBanner
