import React from 'react'
import { useTranslation } from 'react-i18next'

const WhyGoSolarBottomPart = () => {
  const { t, i18n } = useTranslation()

  const cardContents = [
    {
      title: t('bottomPart.documentationAgreements.title'),
      content: t('bottomPart.documentationAgreements.content'),
    },
    {
      title: t('bottomPart.siteAssessmentEligibility.title'),
      content: t('bottomPart.siteAssessmentEligibility.content'),
    },
    {
      title: t('bottomPart.installationPermits.title'),
      content: t('bottomPart.installationPermits.content'),
    },
    {
      title: t('bottomPart.utilityGridConnection.title'),
      content: t('bottomPart.utilityGridConnection.content'),
    },
    {
      title: t('bottomPart.switchToSolarPower.title'),
      content: t('bottomPart.switchToSolarPower.content'),
    },
    {
      title: t('bottomPart.workerOnRoof.title'),
      content: t('bottomPart.workerOnRoof.content'),
    },
  ]

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-10 px-20">
        {cardContents.map(({ title, content }, index) => (
          <div
            key={index}
            className="p-4 bg-gray-800 text-yellow-500 rounded-lg h-auto flex flex-col justify-center items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            <h4 className="text-xl sm:text-2xl md:text-3xl font-bold mb-2 text-center">
              {title}
            </h4>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-center">
              {content}
            </p>
          </div>
        ))}
      </div>
    </>
  )
}

export default WhyGoSolarBottomPart
