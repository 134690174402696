import React from 'react'
import { useTranslation } from 'react-i18next'

const AnimatedBanner = () => {
  const { t } = useTranslation()

  return (
    <div className="relative w-full h-96 overflow-hidden rounded-lg shadow-lg">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src="/images/animatedBanner.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-gray-900 via-transparent to-black opacity-70"></div>
      <div className="relative z-10 flex flex-col items-start justify-center h-full text-gray-100 p-4 sm:p-8 md:p-12 lg:p-20 transform hover:scale-105 transition-transform duration-700 ease-in-out shadow-lg overflow-hidden">
        <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl tracking-tight leading-tight">
          {t('kktEngineering')}
        </h1>
        <p className="text-yellow-400 font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl mt-2 sm:mt-4 leading-tight">
          {t('poweringTheFuture')}
        </p>
      </div>
    </div>
  )
}

export default AnimatedBanner
