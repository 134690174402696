import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const VerticalBanners = () => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState([])
  const modalRef = useRef(null)

  const handleButtonClick = (details) => {
    // Ensure that details are always treated as an array
    setModalContent(Array.isArray(details) ? details : [details])
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setModalContent([])
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal()
      }
    }

    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isModalVisible])

  const bannerData = [
    {
      title: t('solarPanelsForYou'),
      body: t('solarPanelsForYouBody'),
      details: [
        t('solarPanelsForYouDetailsFirst'),
        t('solarPanelsForYouDetailsSecond'),
        t('solarPanelsForYouDetailsThird'),
      ],
      textColor: 'text-white',
      bgColor: 'bg-vertical-banner-1',
    },
    {
      title: t('personalProject'),
      body: t('personalProjectBody'),
      details: [
        t('personalProjectDetailsFirst'),
        t('personalProjectDetailsSecond'),
        t('personalProjectDetailsThird'),
        t('personalProjectDetailsFourth'),
      ],
      textColor: 'text-yellow-500',
      bgColor: 'bg-vertical-banner-2',
    },
    {
      title: t('easyInstallation'),
      body: t('easyInstallationBody'),
      details: [
        t('easyInstallationDetailsFirst'),
        t('easyInstallationDetailsSecond'),
        t('easyInstallationDetailsThird'),
      ],
      textColor: 'text-white',
      bgColor: 'bg-vertical-banner-3',
    },
    {
      title: t('maintenance'),
      body: t('maintenanceBody'),
      details: [
        t('maintenanceDetailsFirst'),
        t('maintenanceDetailsSecond'),
        t('maintenanceDetailsThird'),
        t('maintenanceDetailsFourth'),
      ],
      textColor: 'text-yellow-500',
      bgColor: 'bg-vertical-banner-4',
    },
  ]

  return (
    <div className="w-full font-bold">
      <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0">
        {bannerData.map((banner, index) => (
          <div
            key={index}
            className={`${banner.bgColor} bg-cover bg-center h-96 md:h-160 w-full md:w-1/4 flex flex-col p-4 rounded-lg transition-transform transform hover:scale-105 hover:shadow-xl`}
          >
            <div className={`pt-12 text-center ${banner.textColor}`}>
              <span className="text-3xl md:text-4xl font-bold">
                {banner.title}
              </span>
            </div>
            <div className="flex justify-center mt-auto pb-10">
              <button
                className="mt-4 px-4 py-2 bg-yellow-500 text-gray-900 text-sm md:text-base lg:text-lg font-semibold rounded-full hover:bg-yellow-600 transition duration-300"
                onClick={() => handleButtonClick(banner.details)}
              >
                {t('learnMore')}
              </button>
            </div>
          </div>
        ))}
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-gradient-to-r from-gray-800 to-gray-900 text-white p-4 sm:p-6 rounded-lg transition-transform transform hover:scale-105 shadow-xl max-w-xs sm:max-w-sm md:max-w-md mx-4 sm:mx-auto"
          >
            <div className="text-center text-sm sm:text-lg mb-4">
              {modalContent.map((paragraph, index) => (
                <p key={index} className="mb-5">
                  {paragraph}
                </p>
              ))}
            </div>
            <button
              className="mt-4 px-4 py-2 bg-yellow-500 text-black font-semibold rounded-full hover:bg-yellow-600 transition duration-300"
              onClick={closeModal}
            >
              {t('close')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default VerticalBanners
