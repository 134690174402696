import React, { useState, useEffect } from 'react'
import { FaArrowUp } from 'react-icons/fa'

const BackToTopButton = () => {
  // State to track visibility of the button
  const [isVisible, setIsVisible] = useState(false)

  // Function to handle the scroll event
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      // Show button after scrolling down 300px
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // Adding the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-8 right-8 bg-yellow-500 text-gray-900 rounded-full p-4 shadow-lg hover:bg-yellow-600 transition duration-300 flex items-center justify-center ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <FaArrowUp size={24} />
    </button>
  )
}

export default BackToTopButton
