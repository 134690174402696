import React from 'react'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="pb-10 px-4 sm:px-10 md:px-20">
        <div className="p-4 sm:p-6 bg-gradient-to-r from-gray-800 to-black text-yellow-500 rounded-lg h-auto sm:h-96 flex flex-col justify-center items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-2">
            {t('whoAreWe')}
          </h2>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl max-w-md sm:max-w-xl">
            {t('kktSpecializes')}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-10 px-4 sm:px-10 md:px-20">
        {[
          { title: 'expertiseYouCanTrust', body: 'expertiseYouCanTrustBody' },
          { title: 'unmatchedQuality', body: 'unmatchedQualityBody' },
          { title: 'personalSolarServices', body: 'personalSolarServicesBody' },
          { title: 'ourResponsibility', body: 'ourResponsibilityBody' },
        ].map(({ title, body }, index) => (
          <div
            key={index}
            className="p-4 sm:p-6 bg-gray-800 text-yellow-500 rounded-lg flex flex-col justify-center items-center text-center min-h-[0] transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-4">
              {t(title)}
            </h3>
            <p className="text-sm sm:text-base md:text-lg">{t(body)}</p>
          </div>
        ))}
      </div>

      <div className="px-4 sm:px-10 md:px-20 pb-10">
        <div className="bg-solar-panel h-40 sm:h-64 rounded-lg p-4 transition-transform transform hover:scale-105 hover:shadow-xl"></div>
      </div>
    </>
  )
}

export default About
