import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.css';
import LanguageBanner from "./components/LanguageBanner";
import AnimatedBanner from "./components/AnimatedBanner";
import WhyChooseSolarEnergy from "./components/WhyChooseSolarEnergy";
import HowCanWeHelpYou from "./components/HowCanWeHelpYou";
import VerticalBanners from "./components/VerticalBanners";
import ExploreBanner from "./components/ExploreBanner";
import Footer from "./components/Footer";
import WhyGoSolarPage from './components/WhyGoSolar/WhyGoSolar';
import About from './components/About/About';
import BackToTopButton from "./components/BackToTop";
import './i18n';

function App() {
  return (
      <>
        <Helmet>
        <title>KKT - Engineering</title>
        <link rel="icon" type="image/png" href="../public/favicon.ico"/>
      </Helmet>
        <Router>
      <div className="flex flex-col bg-fixed min-h-screen bg-background-kkt-app bg-cover bg-no-repeat">
        <div className="pb-10 px-20"><LanguageBanner /></div>

        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <div className="pb-10 px-20"><AnimatedBanner/></div>
                  <div className="pb-10 px-20"><WhyChooseSolarEnergy/></div>
                  <div className="pb-10 px-20"><HowCanWeHelpYou/></div>
                  <div className="pb-10 px-20"><VerticalBanners/></div>
                  <div className="pb-10 px-20"><ExploreBanner/></div>
                </>
              }
            />
            <Route path="/why-go-solar" element={<WhyGoSolarPage />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
        <Footer />
        <BackToTopButton />
      </div>
    </Router>
      </>
  );
}

export default App;
