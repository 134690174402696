import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ExploreBanner = () => {
  const { t } = useTranslation()

  return (
    <div className="p-6 bg-gradient-to-r text-center from-gray-800 to-gray-900 text-yellow-500 rounded-lg flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl overflow-hidden">
      <div className="text-gray-300 font-bold text-lg sm:text-2xl md:text-3xl lg:text-4xl leading-tight">
        <span>{t('companiesTitle')}</span>
      </div>
      <div className="text-base sm:text-lg md:text-xl lg:text-2xl mb-2">
        <span>{t('likes')}</span>
      </div>
      <div className="flex flex-wrap items-center justify-center space-x-6 mt-2">
        <Link
          to="https://www.solaredge.com/us/"
          className="hover:underline mx-1"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/solaredge.png`}
            alt="SolarEdge"
            className="h-4 sm:h-6 md:h-8 lg:h-10 w-auto object-contain"
          />
        </Link>
        <Link to="https://www.kstar.com/" className="hover:underline mx-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/kstar.png`}
            alt="KStar"
            className="h-4 sm:h-6 md:h-8 lg:h-10 w-auto object-contain"
          />
        </Link>
        <Link to="https://en.growatt.com/" className="hover:underline mx-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/growatt.png`}
            alt="Growatt"
            className="h-4 sm:h-6 md:h-8 lg:h-10 w-auto object-contain"
          />
        </Link>
        <Link to="https://vandervalk.com/" className="hover:underline mx-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/vandervalk.png`}
            alt="Vander Valk"
            className="h-4 sm:h-6 md:h-8 lg:h-10 w-auto object-contain"
          />
        </Link>
      </div>
    </div>
  )
}

export default ExploreBanner
